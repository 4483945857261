import { OrderType } from './order-type.model';
import { OrderStatus } from './order-status.model';
import { Address } from './address.model';
import { User } from './user.model';
import { ShippingMethod } from './shipping-method.model';
import { CreditTerm } from './credit-term.model';
import { Contact } from './contact.model';
import { Customer } from './customer.model';

export class Order {
  datetime_created: string;
  datetime_updated: string;
  order_number: string;
  qr_code: string;
  is_tax_exempt: boolean;
  customer_po: string;
  is_dropship_order: boolean;
  is_rush_order: boolean;
  is_repeat_order: boolean;
  is_pricing_tbd: boolean;
  order_date: string;
  promised_shipping_date: string;
  estimated_shipping_date: string;
  actual_shipping_date: string;
  req_in_hand_date: string;
  status_due_date: string;
  expiry_date: string;
  order_comment: string;
  next_action: string;
  shipping_total: number;
  shipping_total_canceled: number;
  shipping_total_refunded: number;
  shipping_total_invoiced: number;
  tax_total: number;
  tax_total_canceled: number;
  tax_total_refunded: number;
  tax_total_invoiced: number;
  discount_total: number;
  discount_total_canceled: number;
  discount_total_refunded: number;
  discount_total_invoiced: number;
  sub_total: number;
  sub_total_canceled: number;
  sub_total_refunded: number;
  sub_total_invoiced: number;
  grand_total: number;
  total_canceled: number;
  total_refunded: number;
  total_invoiced: number;
  store: number;
  managed_by: User;
  customer: Customer;
  contact: Contact;
  shipping_address: Address;
  billing_address: Address;
  order_type: OrderType;
  credit_term: CreditTerm;
  order_status: OrderStatus;
  shipping_method: ShippingMethod;
  shipping_override: any;
  payment_notes: string;
  price_data = {
    'price_adjustments': []
  };
  is_locked: boolean;
  is_guest: boolean;
  is_price_locked: boolean;
  is_shipped: boolean;
  is_blind_shipping: boolean;
  include_supplier_shipping: boolean;
  is_shipping_override: boolean;
  is_multiple_shipment: boolean;
  product_item_count: number;
}
