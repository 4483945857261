<app-header></app-header>
<!-- <app-topnav></app-topnav> -->
<div class="app-body">
  <app-sidebar></app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <div class="d-flex justify-content-between">
      <ol class="breadcrumb">
        <app-breadcrumbs></app-breadcrumbs>
      </ol>
    </div>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </main>
</div>
<div class="overlay"></div>