import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
})
export class AddressDetailsComponent {
  @Input() address;

  constructor() { }
}
