<nav style="--bs-breadcrumb-divider: '>''';" aria-label="breadcrumb">
  <ol class="breadcrumb ms-2 mt-2">
  <li class="breadcrumb-item">
    <a [routerLink]="'/'">Home</a>
  </li>
  <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last = last>
    <li class="breadcrumb-item" [ngClass]="{active: last}">
      <a *ngIf="breadcrumb.status && !last" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
      <a *ngIf="!breadcrumb.status && !last" class="not-active" >{{ breadcrumb.label }}</a>
      <span *ngIf="last">{{ breadcrumb.label }}</span>
    </li>
  </ng-template>
  </ol>
</nav>