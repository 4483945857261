import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  loadingNavigation!: boolean;
  items: any = [
    { icon: 'bi-speedometer2', title: 'Dashboard' },
    { icon: 'bi-speedometer2', title: 'Order' },
    { icon: 'bi-speedometer2', title: 'Account Details' },
  ];

}
