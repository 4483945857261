<app-table [loading]="vendorOrdersLoading" [totalResults]="vendorOrders.count" [params]="params" [columns]="columns"
  [config]="config" [data]="vendorOrders['results']" (paramsChanged)="onParamsChange($event)">
  <div class="row" additionalFilters>
    <div class="col-3 calendar-wrap ps-md-0">
      <input class="form-control" [owlDateTimeTrigger]="dt1" [formControl]="fromDateFormControl" [owlDateTime]="dt1"
        placeholder="From">
      <app-icon name="x-lg" class="cursor-pointer calendar-close-icon" *ngIf="fromDateFormControl.value"
        (click)="clearDateFilter(fromDateFormControl)"></app-icon>
      <app-icon name="calendar3" class="cursor-pointer calendar-icon" [owlDateTimeTrigger]="dt1">
      </app-icon>
      <i class="fa fa-calendar cursor-pointer" [owlDateTimeTrigger]="dt1"></i>
      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
    </div>
    <div class="col-3 calendar-wrap ps-md-0">
      <input class="form-control" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" [formControl]="toDateFormControl"
        placeholder="To">
      <app-icon name="x-lg" class="cursor-pointer calendar-close-icon" *ngIf="toDateFormControl.value"
        (click)="clearDateFilter(toDateFormControl)"></app-icon>
      <app-icon name="calendar3" class="cursor-pointer calendar-icon" [owlDateTimeTrigger]="dt2">
      </app-icon>
      <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
    </div>
    <div class="col-md-1 bt-go d-grid gap-2">
      <button (click)="dateRangeFilter()" class="btn btn-primary btn-block mt-3 mt-sm-3 mt-md-0">Go</button>
    </div>
    <div class="col-3"  *ngIf="!hideDashboard">
      <select class="form-select" (change)="customFilters($event, 'order_status')">
        <option value="">All Status</option>
        <option *ngFor="let item of orderOptions.order_status_list" [selected]="item.id === selectedStatus"
          [value]="item.id">{{item.name}}</option>
      </select>
    </div>
  </div>
  <tbody table body>
    <tr *ngFor="let item of vendorOrders['results']; let i=index" (click)="trClick(item.vendor_order_number, $event)"
      class="cursor-pointer">
      <td class="text-start">{{item?.order?.order_number}}</td>
      <td>
        <a (click)="viewVendorDetails(item.vendor_order_number)">{{item.vendor_order_number}}</a>
      </td>
      <td class="text-start">{{item.order.order_date | date: 'MM/dd/YYYY'}}</td>
      <td class="text-start">{{item.due_date}}</td>
      <td class="text-start">{{item?.order?.nav_id}}</td>
      <td class="text-start text-capitalize">{{item.type | removeUnderscore}}</td>
      <td class="text-center">
        {{item?.ship_action === 'non_shipping' ? 1 : item.total_item_qty}}
      </td>
      <td class="text-start text-capitalize">{{shipaction[item.ship_action]}}</td>
      <td class="text-start text-capitalize">{{item.order_status?.name}}</td>    
      <td class="action-btn text-center btn-status-action">
        <a class="me-1 cursor-pointer btn ps-0 fw-bold view-details-icon" (click)="viewVendorDetails(item.vendor_order_number)" container="body"
          placement="top" ngbTooltip="View Details">
          <i class="bi bi-eye-fill"></i> </a>
        <ng-container *ngIf="item.status === 'send'">
          <button type="button" class="btn btn-primary btn-sm text-white button-wrap" (click)="changeStatus(item.vendor_order_number, 'accept')">
            <app-icon class="text-white font-xs" name="check-lg"></app-icon> Accept
          </button>
          <button type="button" class="btn btn-sm btn-danger btn-reject button-wrap" (click)="changeStatus(item.vendor_order_number, 'reject')">
            <app-icon class="text-white font-xs" name="x-lg"> </app-icon> Reject
          </button>
        </ng-container>
        <ng-container *ngIf="item.status === 'in_progress'">
          <button class="btn btn-success btn-sm text-white button-complete" (click)="onMarkAsComplete(item.vendor_order_number)">
            <app-icon class="text-white font-xs" name="check-lg"></app-icon> Mark As Completed
          </button>
        </ng-container>
      </td>
    </tr>
    <tr *ngIf="vendorOrders.count === 0">
      <td [attr.colspan]="columns.length">No data available in table</td>
    </tr>
  </tbody>
</app-table>