
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Observable, of as observableOf, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ApiService, AuthService, StorageService } from '@shared/index';
import { Order } from '../models/order.model';
import { E } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private environment;
  private currentOrderSubject = new BehaviorSubject<Order>(new Order());
  currentOrder = this.currentOrderSubject.asObservable();

  orderTotalSubject = new BehaviorSubject<any>(null);
  orderTotal = this.orderTotalSubject.asObservable();

  activeTabSubject = new BehaviorSubject<any>('dashboard');
  activeTab = this.activeTabSubject.asObservable();

  emitChange = new BehaviorSubject<any>(null);

  private moduleUrl = '/vendor_orders';
  orderTabId = '';
  orderTabs: Array<any> = [];
  currentOrderTab = [];
  parentRoute: any;


  isInsertDecorationList = [
    'embroidered_specials',
    'emb_penn_edge_pg',
    'emb_penn_edge_eg',
    'penn_stitc',
  ];

  threadSupportedDecorationMethdods = [
    'flat_embroidery',
    'three_dimension_embroidery',
    'text_embroidery',
    'other',
    'handcut',
    'embroidered_specials',
    'es_letter',
    'pennbroidery',
    'emb_penn_edge_eg',
    'emb_penn_edge_pg',
    'penn_stitch'
  ];
  sharedDecorationList = [
    'dye_sublimated',
    'handcut',
    'embroidered_specials',
    'es_letter',
    'es_pg_letter',
    'ij_letter',
    'pennbroidery',
    'emb_penn_edge_eg',
    'emb_penn_edge_pg',
    'silk_penn_edge_rh',
    'silk_penn_edge_rt',
    'penn_flex_badge',
    'penn_flex_dome',
    'penn_flex_logo',
    'penn_stitch',
    'penn_trans',
    'penn_weave',
    'silk_screen_emblem',
    'ts_letter',
    'ts_printed_letter',
    'sublimated_label',
    'woven_label',
    'ink_jet',
    'ig_emblem',
    'silk_screen_printed_emblem',
    'handcut',
    'etched_leather',
    'silk_penn_edge_rt_letter',
    'silk_penn_edge_rh_letter',
    'es_eg_letter',
    'penn_trans_plh',
    'penn_trans_pli'
  ];

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    @Inject('environment')
    environment
  ) {
    this.environment = environment;
  }

  getOrderTabs() {
    const user = this.storage.retrieve('user');
    if (user && user['settings']) {
      this.orderTabs = [];
      const parentRoute = this.getParentRoute();
      const pageCode = parentRoute + '-' + this.getPageCode();
      const userSettings = user['settings'];
      if (userSettings['vendor_order_tabs'] && userSettings['vendor_order_tabs'][pageCode]) {
        this.orderTabs = userSettings['vendor_order_tabs'][pageCode];
      }
    }
    return this.orderTabs;
  }

  getPageCode() {
    const path = this.router.url.split('/');
    if (path.length >= 3) {
      return path[2];
    }
    return 'all-orders';
  }

  getParentRoute() {
    const path = this.router.url.split('/');
    if (path.length >= 3) {
      return path[1];
    }
    return 'orders';
  }

  setActiveTab(id) {
    const parentRoute = this.getParentRoute();
    const pageCode = parentRoute + '-' + this.getPageCode();
    let tab = {
      order_number: id,
    };

    const user = this.storage.retrieve('user');
    if (user && user['settings']) {
      const data = user['settings'];

      if (data['vendor_order_tabs']) {
        if (data['vendor_order_tabs'][pageCode]) {
          const orderTabIndex = this.getOrderTabIndex(data['vendor_order_tabs'][pageCode], tab.order_number);
          if (orderTabIndex < 0) {
            data['vendor_order_tabs'][pageCode].push(tab);
          }
        } else {
          data['vendor_order_tabs'][pageCode] = [tab];
        }
      } else {
        const tmp = {};
        if (data['vendor_order_tabs']) {
          tmp[pageCode] = [tab];
          data['vendor_order_tabs'] = tmp;
        } else {
          tmp[pageCode] = [tab];
          data['vendor_order_tabs'] = tmp;
        }
      }
      this.updateUserSettings(data, id, tab);
    } else {
      this.orderTabs.push(tab);
      this.activeTabSubject.next(id);
    }
  }

  removeOrderTab(i, route?) {
    const orderTabs = this.getOrderTabs();
    orderTabs.splice(i, 1);
    const parentRoute = this.getParentRoute();
    const pageCode = parentRoute + '-' + this.getPageCode();
    const user = this.storage.retrieve('user');
    let data = {};
    if (user && user['settings']) {
      data = user['settings'];
      data['vendor_order_tabs'][pageCode] = orderTabs;
    }

    if (route) {
      parent = route;
    }
    this.updateUserSettings(data, -1);
    const childRoute = this.getPageCode();
    this.router.navigate(['./' + parent + '/' + childRoute]);
  }

  removeOrderTabFromSettings(id) {
    const orderTabs = this.getOrderTabs();
    const i = orderTabs.findIndex(x => x.order_number === id);
    if (i >= 0) {
      orderTabs.splice(i, 1);
    }

    const user = this.storage.retrieve('user');
    let data = {};
    if (user && user['settings']) {
      data = user['settings'];
      for (const key in data['vendor_order_tabs']) {
        if (key) {
          const index = data['vendor_order_tabs'][key].findIndex(x => x.order_number === id);
          if (index >= 0) {
            data['vendor_order_tabs'][key].splice(index, 1);
          }
        }
      }
    }
    this.updateUserSettings(data, -1);
  }

  getParentPage(pageCode) {
    if (pageCode && pageCode.indexOf('orders') < 0) {
      return 'quotes';
    }

    return 'orders';
  }

  getOrderTabIndex(orderTabs, id) {
    return orderTabs.findIndex(x => x.order_number === id);
  }

  updateUserSettings(data, activeTabIndex, tab?) {
    const settings = {
      'settings': data
    };
    const userDetails = this.authService.getAuthUserDetails();
    this.patchEmployeeData(settings, userDetails.id).subscribe(response => {
      const user = this.storage.retrieve('user');
      if (user && user['settings']) {
        user['settings'] = response['settings'];
        this.storage.store('user', user);
      }
      if (activeTabIndex < 0) {
        this.activeTabSubject.next('dashboard');
      } else {
        this.activeTabSubject.next(activeTabIndex);
      }
    }, error => {
      if (tab) {
        const orderTabIndex = this.getOrderTabIndex(this.orderTabs, tab.order_number);
        if (orderTabIndex < 0) {
          this.orderTabs.push(tab);
        }
        this.activeTabSubject.next(tab.order_number);
      }

    });
  }

  patchEmployeeData(updateData: any, user_id: any): Observable<any> {
    return this.apiService
      .patch('/auth-user/me', updateData).pipe(
        map(data => {
          return data;
        }));
  }

  updateActiveTabPage(id) {
    const parentRoute = this.getParentRoute();
    const pageCode = parentRoute + '-' + this.getPageCode();
    let tab = {
      order_number: id,
    };
    const user = this.storage.retrieve('user');
    if (user && user['settings']) {
      const data = user['settings'];
      if (data['vendor_order_tabs']) {
        if (data['vendor_order_tabs'][pageCode]) {
          const orderTabIndex = this.getOrderTabIndex(data['vendor_order_tabs'][pageCode], tab.order_number);
          if (orderTabIndex < 0) {
            data['vendor_order_tabs'][pageCode].push(tab);
            this.updateUserSettings(data, id);
          }
        } else {
          data['vendor_order_tabs'][pageCode] = [tab];
          this.updateUserSettings(data, id);
        }
      } else {
        const tmp = {};
        if (data['vendor_order_tabs']) {
          tmp[pageCode] = [tab];
          data['vendor_order_tabs'] = tmp;
        } else {
          tmp[pageCode] = [tab];
          data['vendor_order_tabs'] = tmp;
        }
        this.updateUserSettings(data, id);
      }
    }
  }

  getTabPageDetails(id) {
    const orderTabs = this.getOrderTabs();
    if (orderTabs.find(x => x.order_number === id)) {
      return orderTabs.find(x => x.order_number === id).page;
    } else {
      return 'order-summary';
    }
  }


  getOrders(params: any): Observable<any> {
    return this.apiService.get(this.moduleUrl, params).pipe(
      map(
        data => data
      ));
  }

  getOrderDetails(id: string) {
    return this.apiService.get(this.moduleUrl + '/' + id + '/').pipe(
      map(data => {
        return data;
      }));
  }

  reloadOrderDetails(id) {
    this.getOrderDetails(id).subscribe(response => {
      this.currentOrderSubject.next(response);
      this.orderTotalSubject.next(response.grand_total);
      this.storage.store('order-' + id, response, 'session');
      return response;
    });
  }

  setCurrentOrderData(data) {
    this.currentOrderSubject.next(data);
    this.orderTotalSubject.next(data.grand_total);
  }

  setOrderTotal(total) {
    this.orderTotalSubject.next(total);
  }

  save(req: any): Observable<any> {
    if (req.id) {
      const url = `${this.moduleUrl}/${req.id}`;
      return this.apiService
        .put(url, req).pipe(
          map(response => {
            return response;
          }));
    }
    return this.apiService
      .post(this.moduleUrl, req).pipe(
        map(response => {
          return response;
        }));
  }

  getOrderSummary(): Observable<any> {
    const url = `${this.moduleUrl}/summary/`;
    return this.apiService.get(url).pipe(
      map(response => {
        return response;
      })
    );
  }

  updateVendorOrder(orderNumber: string, action: string): Observable<any> {
    const url = `${this.moduleUrl}/${orderNumber}/${action}/`;
    return this.apiService.patch(url, {}).pipe(
      map(response => {
        return response;
      }));
  }

  saveVendorLogoFiles(values, vendorId, artworkId) {
    const url = `/vendor_orders/${vendorId}/vendor_order_artwork/${artworkId}/vendor_logo_files`;
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('vendor_order_artwork', artworkId);
    formData.append('file_type', values.file_type);

    return this.apiService
      .postFile(url, formData).pipe(
        map(response => {
          return response;
        }));
  }

  saveVendorOrderArtwork(workOrderNumber: string, req: any) {
    const url = `/vendor_orders/${workOrderNumber}/artworks/${req.id}/`;
    return this.apiService
      .patch(url, req).pipe(
        map(response => {
          return response;
        }));
  }

  getVendorOrderArtwork(workOrderNumber: string, artworkId: string) {
    const params = new HttpParams().append('all', 'true');
    const url = `/vendor_orders/${workOrderNumber}/artworks/${artworkId}/`;
    return this.apiService
      .get(url, params).pipe(
        map(response => {
          return response;
        }));
  }

  deleteAdditionalFile(vendorId: string, artworkId: any, fileId: any) {
    const params = new HttpParams().append('logo_ids', fileId);
    const url = `/vendor_orders/${vendorId}/vendor_order_artwork/${artworkId}/vendor_logo_files/`;
    return this.apiService
      .delete(url, params).pipe(
        map(response => {
          return response;
        }));
  }

  getOrderPdf(vendorOrderNumber) {
    const url = `${this.moduleUrl}/${vendorOrderNumber}/purchase_order_pdf/`;
    return this.apiService.getFile(url);
  }

  getOrderStatus() {
    const statusArr = this.storage.retrieve('orderStatus');
    if (statusArr) {
      return observableOf(statusArr);
    } else {
      const url = '/vendor_order_status/';
      return this.apiService.get(url).pipe(
        map(cart => cart.id),
        tap(response => {
          this.storage.store('orderStatus', response);
        })
      );
    }
  }

  saveOrderStatus(id: string, req: any): Observable<any> {
    const url = `${this.moduleUrl}/${id}/change_status/`;
    return this.apiService
      .patch(url, req).pipe(
        map(response => {
          return response;
        }));
  }

  getShipmentPendingItems(id: string): Observable<any> {
    const url = `${this.moduleUrl}/${id}/shipment_pending_line_item/`;
    return this.apiService.get(url).pipe(
      map(response => {
        return response;
      }));
  }

  generateLabel(orderId, shipmentId, params: HttpParams): Observable<any> {
    const url = `${this.moduleUrl}/${orderId}/shipments/${shipmentId}/label`;
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  voidLabel(orderId, shipmentId, params: HttpParams): Observable<any> {
    const url = `${this.moduleUrl}/${orderId}/shipments/${shipmentId}/void`;
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Save Shipments */
  saveShipment(details, orderId): Observable<any> {
    const formData = new FormData();
    for (const key in details) {
      if (key) {
        if (details[key] && typeof details[key] === 'object' && details[key].constructor === Object) {
          for (const k in details[key]) {
            if (k) {
              formData.append(key + '.' + k, details[key][k]);
            }
          }
        } else if (details[key] && details[key].constructor === Array) {
          formData.append(key, JSON.stringify(details[key]));
        } else {
          formData.append(key, details[key]);
        }
      }
    }
    let url = `${this.moduleUrl}/${orderId}/shipments/`;
    if (details.id) {
      url += `${details.id}/`;
      return this.apiService
        .putFile(url, formData).pipe(
          map(data => {
            return data;
          }));
    } else {
      return this.apiService
        .postFile(url, formData).pipe(
          map(data => {
            return data;
          }));
    }
  }

  /** Save Shipments Package */
  saveShipmentPackage(details, orderId, shipmentId): Observable<any> {
    if (details.shipment_package_items) {
      details.shipment_package_items.forEach(element => {
        if (element.id === '') {
          delete element.id;
        }
      });
    }
    const formData = new FormData();
    for (const key in details) {
      if (key) {
        if (details[key] && typeof details[key] === 'object' && details[key].constructor === Object) {
          if (key === 'data') {
            formData.append('data', JSON.stringify(details.data));
          } else {
            for (const k in details[key]) {
              if (k) {
                formData.append(key + '.' + k, details[key][k]);
              }
            }
          }
        } else if (details[key] && details[key].constructor === Array) {
          formData.append(key, JSON.stringify(details[key]));
        } else {
          formData.append(key, details[key]);
        }
      }
    }
    let url = `${this.moduleUrl}/${orderId}/shipments/${shipmentId}/packages/`;
    if (details.id) {
      url += `${details.id}/`;
      return this.apiService
        .putFile(url, formData).pipe(
          map(data => {
            return data;
          }));
    } else {
      return this.apiService
        .postFile(url, formData).pipe(
          map(data => {
            return data;
          }));
    }
  }

  saveShipmentDocument(details, orderId, shipmentId): Observable<any> {
    const formData = new FormData();
    for (const key in details) {
      if (key) {
        if (details[key] && typeof details[key] === 'object' && details[key].constructor === Object) {
          if (key === 'data') {
            formData.append('data', JSON.stringify(details.data));
          } else {
            for (const k in details[key]) {
              if (k) {
                formData.append(key + '.' + k, details[key][k]);
              }
            }
          }
        } else if (details[key] && details[key].constructor === Array) {
          formData.append(key, JSON.stringify(details[key]));
        } else {
          formData.append(key, details[key]);
        }
      }
    }
    let url = `${this.moduleUrl}/${orderId}/shipments/${shipmentId}/docs/`;
    if (details.id) {
      url += `${details.id}/`;
      return this.apiService
        .putFile(url, formData).pipe(
          map(data => {
            return data;
          }));
    } else {
      return this.apiService
        .postFile(url, formData).pipe(
          map(data => {
            return data;
          }));
    }
  }

  getShipments(orderId: string): Observable<any> {
    const url = `${this.moduleUrl}/${orderId}/shipments/`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  patchShipmentStatus(shipment: any, orderNumber: string) {
    const url = `${this.moduleUrl}/${orderNumber}/shipments/${shipment.id}/mark_complete/`;
    return this.apiService
      .patch(url, {}).pipe(
        map(response => {
          return response;
        }));
  }

  /** Delete Shipment */
  deleteShipment(shipment: any, orderNumber: string) {
    const url = `${this.moduleUrl}/${orderNumber}/shipments/${shipment.id}/`;
    return this.apiService
      .delete(url).pipe(
        map(response => {
          return response;
        }));
  }

  printShipment(shipment: any, orderNumber: string) {
    const url = `${this.moduleUrl}/${orderNumber}/shipments/${shipment.id}/packing_list`;
    return this.apiService.getFile(url);
  }

  getShippingBoxes(params: HttpParams) {
    const url = '/shipping_boxes/';
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));

  }

  /** Save Shipments Package */
  saveShipmentPackages(details, id, shipmentId): Observable<any> {
    if (details.shipment_package_items) {
      details.shipment_package_items.forEach(element => {
        if (element.id === '') {
          delete element.id;
        }
      });
    }
    let url = this.moduleUrl + '/' + id + '/shipments/' + shipmentId + '/packages/';
    if (details.id) {
      url = url + details.id + '/';
      return this.apiService
        .patch(url, details).pipe(
          map(data => {
            return data;
          }));
    } else {
      return this.apiService
        .post(url, details).pipe(
          map(data => {
            return data;
          }));
    }
  }

  /** Delete Shipments Package */
  deleteShipmentPackages(orderId: string, shipmentId: string, packageId: string): Observable<any> {
    const url = `${this.moduleUrl}/${orderId}/shipments/${shipmentId}/packages/${packageId}/`;
    return this.apiService
      .delete(url).pipe(
        map(data => {
          return data;
        }));
  }

  deleteShipmentDocument(orderId: string, shipmentId: string, docId: string): Observable<any> {
    const url = `${this.moduleUrl}/${orderId}/shipments/${shipmentId}/docs/${docId}/`;
    return this.apiService
      .delete(url).pipe(
        map(data => {
          return data;
        }));
  }

  getDstInfo(file) {
    const data = {
      'file': file
    };
    return this.apiService
      .post('/dst_reader', data).pipe(
        map(response => {
          return response;
        }));
  }

  getDstInfoFromUrl(url) {
    const data = {
      'url': url
    };
    return this.apiService
      .post('/dst_reader_from_url', data).pipe(
        map(response => {
          return response;
        }));
  }

  getDecorationOptions(id: string, params: HttpParams): Observable<any> {
    const url = `/decoration_methods/${id}/options/`;
    return this.apiService.get(url, params).pipe(
      map(
        data => data
      ));
  }


  getDecorationSchema(id: string, params: HttpParams): Observable<any> {
    const url = `/decoration_methods/${id}/schema/`;
    return this.apiService.get(url, params).pipe(
      map(
        data => data
      ));
  }
}
