import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, StorageService } from '@shared/index';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private apiService: ApiService,
    private storage: StorageService,
  ) { }


  getVendorOrders(params: HttpParams, status?: string): Observable<any> {
    let url = '/vendor_orders/';
    if (status && status !== '') {
      url += status + '/';
    }
    
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }));
  }

  updateVendorOrder(orderNumber: string, action: string): Observable<any> {
    const url = `/vendor_orders/${orderNumber}/${action}/`;
    return this.apiService.patch(url, {}).pipe(
      map(response => {
        return response;
      }));
  }

  getAdditionalFileTypes() {
    if (!this.storage.retrieve('additionalFileTypes')) {
      this.apiService.get('/additional_file_types')
      .subscribe(response => {
        this.storage.store('additionalFileTypes', response);
      });
    }
  }

  getOrderStatus() {
    if (!this.storage.retrieve('orderStatus')) {
      this.apiService.get('/vendor_order_status/')
      .subscribe(response => {
        this.storage.store('orderStatus', response);
      });
    }
  }

  sentShipmentApproval(orderNumber: string, shipmentId) {
  const url = `/vendor_orders/${orderNumber}/shipments/${shipmentId}/approval_request`;
  return this.apiService.post(url, {}).pipe(
    map(response => {
      return response;
    }));
  }

}