import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { AuthGuard } from '@shared/index';
import { LoginComponent } from './core/login/login.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { LayoutComponent } from '@layout/components/layout.component';
import { NotfoundComponent } from '@layout/components/notfound/notfound.component';



export const AppRoutes: Routes = [

  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: {
          breadcrumb: 'Dashboard',
        }
      },
      {
        path: 'orders',
        loadChildren: () => import('./core/orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: 'examples',
        loadChildren: () => import('./core/examples/example.module').then(m => m.ExampleModule),
      },
      {
        path: 'account-details',
        loadChildren: () => import('./core/account-details/account-details.module').then(m => m.AccountDetailsModule),
      }
    ]
  },
  {
    path: 'setpassword/:key',
    loadChildren: () => import('@core/setpassword/setpassword.module').then(m => m.SetpasswordModule),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '404',
    component: NotfoundComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];


@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
