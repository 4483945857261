import { Component, Input, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SharedService } from 'src/app/shared/services/shared.service';
import { ToasterService, ConfirmationDialogService, UtilityService, StorageService } from '@shared/index';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-order-list',
  templateUrl: 'order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})

export class OrderListComponent implements OnInit {
  @Input() status: any;

  show: Boolean = true;
  shipaction = {
    'ship_to_customer': 'Ship To Customer',
    'ship_to_office': 'Ship To Warehouse',
    'non_shipping': 'Non Shipping'
  };

  /** column definition for table */
  columns: Array<any> = [
    { title: 'Ref #', name: 'ref_number', sort: false },
    { title: 'Order #', name: 'vendor_order_number', sort: false },
    { title: 'Order Date', name: 'order_date', sort: false },
    { title: 'Due Date', name: 'due_date', sort: false },
    { title: 'SO #', name: 'type', sort: false },
    { title: 'Type', name: 'type', sort: false },
    { title: 'Total Quantity', name: 'notes', sort: false, className: 'text-center' },
    { title: 'Shipping', name: 'shipping', sort: false },
    { title: 'Status', name: 'status', sort: false },
    { title: 'Actions', name: 'action', sort: false, className: 'text-center' },
  ];

  /** Table configuration data. */
  config: any = {
    paging: true,
    search: true,
    sorting: true,
    responsive: true,
    className: 'align-middle',
    limit: true,
    additionalFilters: true
  };

  params: HttpParams;

  vendorOrders: any = {
    results: [],
    count: 0
  };
  orderOptions: any = {
    'order_type': []
  };
  vendorOrdersLoading: boolean;

  /** Api Errors */
  errors: any;

  fromDateFormControl = new FormControl();
  toDateFormControl = new FormControl();

  vendorOrderSummary: any;
  loadingVendorOrderSummary: boolean;
  statusList = [];

  inProgressStatusList = [
    'accepted', 'printed', 'send', 'enroute_to_sub_contractor', 'onsite_at_sub_contractor'
  ];
  hideDashboard: boolean;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private storage: StorageService,
    private utils: UtilityService,
    private toastr: ToasterService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute
  ) {
    const routerData = this.route.snapshot.data;
    if (routerData['status']) {
      this.status = routerData['status'];
    }
  }

  ngOnInit() {
    this.initParams();
    this.getVendorOrders();
    this.hideDashboard = this.router.url.includes('dashboard');
    this.retrieveOrderOptionsFromStorage();
  }

  initParams() {
    this.params = new HttpParams()
      .set('limit', environment.tableRowLimit)
      .set('ordering', '-datetime_created');
  }

  retrieveOrderOptionsFromStorage() {
    if (this.storage.retrieve('orderOptions')) {
      this.orderOptions = this.storage.retrieve('orderOptions');

      if (this.orderOptions['order_status_list']) {
        this.statusList = this.orderOptions['order_status_list'];
      }
    }
  }

  customFilters(changedData, type: string) {
    this.params = this.params.delete(type);
    if (changedData.target.value !== '') {
      this.params = this.params.append(type, changedData.target.value);
    }
    this.getVendorOrders();
  }

  getVendorOrders() {
    this.vendorOrdersLoading = true;
    this.sharedService.getVendorOrders(this.params, this.status).subscribe({
      next: (response: any) => {
        this.vendorOrders = response;
        this.vendorOrdersLoading = false;
      }, error: (error: any) => {
        this.vendorOrdersLoading = false;
        this.errors = error;
      }
    });
  }

  trClick(id, e) {
    if (e.target.localName === 'td') {
      this.viewVendorDetails(id);
    }
  }

  clearDateFilter(ctrl) {
    ctrl.setValue(null);
    this.clearDateParams();
  }

  clearDateParams() {
    if (!this.fromDateFormControl.value && !this.toDateFormControl.value) {
      this.vendorOrdersLoading = true;
      this.params = this.params.delete('from_date').delete('to_date');
      this.getVendorOrders();
    }
  }

  dateRangeFilter() {
    if (!this.fromDateFormControl.value || !this.toDateFormControl.value) {
      this.toastr.error('Both From Date and To Date is required');
      return;
    }
    if (this.toDateFormControl.value < this.fromDateFormControl.value) {
      this.toastr.error('To Date must be greater than from Date');
      return;
    }
    this.updateDateParams();
    this.getVendorOrders();
  }

  updateDateParams() {
    this.params = this.params
      .set('from_date', this.utils.formatDate(this.fromDateFormControl.value))
      .set('to_date', this.utils.formatDate(this.toDateFormControl.value));
  }

  onParamsChange(response: any) {
    this.params = response.params;
    this.getVendorOrders();
  }

  /* View Vendor */
  viewVendorDetails(id) {
    let child = 'all-orders';
    if (this.status === 'pending_approval') {
      child = 'pending-approval';
    } else if (this.status === 'in_progress') {
      child = 'in-progress';
    } else if (this.status === 'completed') {
      child = 'completed';
    }
    this.router.navigate(['./orders', child, id]);
  }

  changeStatus(id, statusType) {
    let msg;
    let status;
    if (statusType === 'reject') {
      msg = 'Reject';
      status = 'rejected';
    } else {
      msg = 'Accept';
      status = 'in_progress';
    }
    const ref = this.confirmationDialogService.confirm(`${msg} Order`, `Are you sure you want to ${statusType} this order?`, `${msg}`);
    ref.afterClosed$.subscribe(res => {
      this.vendorOrdersLoading = true;
      if (res) {
        this.sharedService.updateVendorOrder(id, statusType).subscribe(r => {
          this.vendorOrdersLoading = false;
          this.toastr.success(`Order ${msg}ed successfully`);
          if (status === 'in_progress') {
            this.router.navigate(['./orders/in-progress/', id]);
          } else {
            this.getVendorOrders();
          }
        }, (error: any) => {
          this.vendorOrdersLoading = false;
          this.errors = error;
          if (error && error.non_field_errors) {
            error.non_field_errors.forEach(err => {
              this.toastr.error(err);
            });
          }
        });
      }
    });
  }

  onMarkAsComplete(orderNumber) {
    const ref = this.confirmationDialogService.confirm('Mark As Complete', 'Are you sure you want to mark this order as completed?', 'Confirm');
    ref.afterClosed$.subscribe(res => {
      if (res) {
        this.vendorOrdersLoading = true;
        this.sharedService.updateVendorOrder(orderNumber, 'complete').subscribe(r => {
          this.vendorOrdersLoading = false;
          this.toastr.success('Order completed successfully');
          this.router.navigate(['./orders/completed/', orderNumber]);
        }, (error: any) => {
          this.errors = error;
          this.vendorOrdersLoading = false;
          if (error && error.non_field_errors) {
            error.non_field_errors.forEach(err => {
              this.toastr.error(err);
            });
          }
        });
      }
    });
  }

}
