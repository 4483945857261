import { Component, OnInit } from '@angular/core';

// services
import { AuthService } from '@shared/index';
import { SharedService } from './shared/services/shared.service';
import { ServiceWorkerService } from './sw.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private sWService: ServiceWorkerService
  ) {
    this.authService.isAuthenticated.subscribe(response => {
      if (typeof response === 'boolean' && response) {
        this.sharedService.getAdditionalFileTypes();
        this.sharedService.getOrderStatus();
      }
    });
  }

  ngOnInit() {
    this.authService.populate();
    this.sWService.checkForUpdates();
  }

}
