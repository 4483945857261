import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public breadcrumbSubject = new BehaviorSubject<any>(null);
  public getBreadcrumb$ = this.breadcrumbSubject.asObservable().pipe(distinctUntilChanged());

}