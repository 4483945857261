import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { AuthService } from '@shared/index';
import { SharedService } from './shared/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    this.authService.isAuthenticated.subscribe(response => {
      if (typeof response === 'boolean' && response) {
        this.sharedService.getAdditionalFileTypes();
        this.sharedService.getOrderStatus();
      }
    });
  }

  ngOnInit() {
    this.authService.populate();
  }

}
