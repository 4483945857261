import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// services
import { AuthService } from '@shared/index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: any;
  userAcronym: string;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}


  ngOnInit() {
    this.authService.currentUser.subscribe((userData) => {
      this.currentUser = userData;
      if (userData && userData['name']) {
        const matches = userData['name'].match(/\b(\w)/g);
        this.userAcronym = matches.join('');
      }
    });
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigateByUrl('/login');
  }

  menuDestop($event: any) {
    $event.preventDefault();
    const bodyElement = document.body;
    bodyElement.classList.toggle('sidebar-hidden');
  }
  
  menuMobile($event: any) {
    $event.preventDefault();
    const bodyElement = document.body;
    bodyElement.classList.toggle('sidebar-mobile-show');
  }

}
