<div class="login-wrap vh-100 d-flex align-items-center justify-content-center">
  <div class="bg-white rounded login-container w-100 position-relative">
    <app-spinner type="normal" [isSpinnerVisible]="formSubmitted"></app-spinner>
    <ng-container *ngIf="!showFgtPwdForm">
      <div class="text-center bg-light rounded-top">
        <img class="mw-100" src="/assets/images/login-logo.png" alt="logo" />
      </div>
      <div class="login-form px-4 py-5">
        <form [formGroup]="authForm" (ngSubmit)="submitForm()">
          <div class="alert alert-danger" *ngIf="formattedErrors && formattedErrors.length > 0">
            <ng-container *ngFor="let error of formattedErrors">
              <p *ngIf="error !== '' && error !== 'invalid_grant'" class="mb-0">
                {{ error }}
              </p>
            </ng-container>
          </div>
          <div class="input-group border-bottom d-flex align-items-center border-bottom">
            <app-icon name="person-fill" class="text-muted fs-4"></app-icon>
            <input class="form-control bg-white border-0 shadow-none" type="text" formControlName="username"
              placeholder="Username">
          </div>
          <app-validation-errors [submitted]="invalidFormData" [control]="authForm.get('username')">
          </app-validation-errors>

          <div class="input-group border-bottom d-flex align-items-center border-bottom mt-4">
            <app-icon name="lock-fill" class="text-muted fs-4"></app-icon>
            <input class="form-control bg-white border-0 shadow-none" type="password" formControlName="password"
              placeholder="Password">
          </div>
          <app-validation-errors [submitted]="invalidFormData" [control]="authForm.get('password')">
          </app-validation-errors>
          <div class="d-flex justify-content-end mt-4 mb-2">
            <a class="link-danger cursor-pointer" (click)="showFgtPwdForm = !showFgtPwdForm">Forgot Password</a>
          </div>
          <div fxFlex="100" fxLayoutAlign="center center">
            <app-recaptcha-form [formInvalid]="invalidFormData"></app-recaptcha-form>
          </div>
          <button type="submit" class="btn btn-primary text-uppercase text-white w-100 rounded-pill">Login</button>
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="showFgtPwdForm">
      <div class="d-flex align-items-center justify-content-between bg-light py-3 px-4 rounded-top">
        <div class="my-1 d-block fw-bold sign-in-wrap w-100">
          Forgot Your Password
        </div>
        <a (click)="toggleBack()" class="text-right cursor-pointer forgot d-flex align-items-center fw-semi-bold mt-0">
          <app-icon name="arrow-left" class="fs-4 me-2"></app-icon>Back</a>
      </div>
      <form [formGroup]="forgotPasswordForm" [appSpinner]="forgotPwdFormSubmitted" (ngSubmit)="submitForgotPwdForm()">
        <div class="px-4 pt-3 pb-5 forgot-pass-wrap">
          <div class="mt-1 mb-3 forgot-pass-text">
            Please enter your email address below to receive a password reset link.
          </div>
          <div class="input-group border-bottom d-flex align-items-center border-bottom">
            <app-icon name="envelope-fill" class="text-muted fs-4"></app-icon>
            <input class="form-control bg-white border-0 shadow-none" type="text" placeholder="Email*"
              formControlName="email">
          </div>
          <app-validation-errors [submitted]="invalidForgotPwdFormData" [control]="forgotPasswordForm.get('email')">
          </app-validation-errors>
          <app-server-validation-error [errors]="fwdPasswordApiErrors" key="email">
          </app-server-validation-error>
          <button type="submit"
            class="btn btn-primary text-uppercase text-white w-100 rounded-pill mt-4">Submit</button>
        </div>
      </form>

    </ng-container>
  </div>