<header class="app-header navbar">
  <button class="navbar-toggler border-0 mobile-menu d-lg-none" type="button"
    (click)="menuMobile($event)">&#9776;</button>
  <a class="navbar-brand cursor-pointer"></a>
  <button class="navbar-toggler border-0 pt-0 d-md-down-none d-none d-lg-block" type="button"
    (click)="menuDestop($event)">&#9776;</button>

  <ul class="nav navbar-nav ms-auto me-4 user-nav">
    <!-- <li class="nav-item dropdown d-md-down-none d-none d-lg-block">
      <a class="nav-link cursor-pointer" id="notification-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
        <app-icon name="bell"></app-icon><span class="badge rounded-pill bg-danger">2
          <span class="visually-hidden">unread notifications</span>
        </span>
      </a>
      <div aria-labelledby="notification-dropdown" class="dropdown-menu dropdown-menu-end  dropdown-menu-lg p-0">
        <div class="text-left border-bottom p-2">
          <strong>Notifications</strong>
          <a class="cursor-pointer float-end text-primary">
            <small>Mark all as read</small>
          </a>
        </div>
        <div class="custom-scroll ">
          <span class="ps-2 font-sm">You have no new notifications.</span>
        </div>
      </div>
    </li> -->
    <li class="nav-item dropdown">
      <a href class="nav-link dropdown-toggle p-0" id="user-nav-dropdown" data-bs-toggle="dropdown"
        aria-expanded="false">
        <div class="img-avatar position-relative text-acronym float-start">
          <span class="name-text">{{userAcronym}}</span>
        </div>
        <div class="float-start d-flex flex-column">
          <div class="user-name-wrap">
            <span class="d-md-down-none d-none d-lg-block fw-normal float-start user-name">{{currentUser.name}}</span>
            <app-icon name="caret-down-fill" class="font-xxs ms-1"></app-icon>
          </div>
          <div class="d-block">
            <small
              class="d-md-down-none d-none d-lg-block user-name text-lowercase fw-normal">{{currentUser.email}}</small>
          </div>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-end" aria-labelledby="user-nav-dropdown">
        <a class="dropdown-item" type="button" (click)="logout()">
          <app-icon name="lock-fill" class="me-1"></app-icon>Logout
        </a>
      </div>
    </li>
  </ul>
</header>