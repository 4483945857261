<section class="dashboard-data-wrap">
  <div class="total-order-summery mb-3">
    <div class="d-flex flex-wrap align-items-center">
      <div class="order-summery-title me-4">
        <h5 class="fw-semi-bold"> ORDER SUMMARY</h5>
      </div>
      <div class="label-count-wrap mb-2">
        <label class="btn btn-pending">Order Pending Approval <span>{{orderSummary?.pending_approval}}</span></label>
      </div>
      <div class="label-count-wrap mb-2">
        <label class="btn btn-progress">Order In Progress <span>{{orderSummary?.in_progress}}</span></label>
      </div>
      <div class="label-count-wrap mb-2">
        <label class="btn btn-completed">Order Completed <span>{{orderSummary?.completed}}</span></label>
      </div>
    </div>
  </div>
  <div class="order-data-container">
    <div class="card">
      <div class="card-header bg-darkgray fw-medium">
        ORDER LIST
      </div>
      <div class="card-body">
        <div class="tab-wrap">
          <app-tabset>
            <app-tab id="pending_approval">
              <ng-template appTabTitle>
                <app-icon name="flag-fill" class="flag-icon flag-approval"></app-icon>Pending Approval <span
                  class="ms-3">{{orderSummary?.pending_approval}}</span>
              </ng-template>
              <ng-template appTabContent>
                <app-order-list status="pending_approval"></app-order-list>
              </ng-template>
            </app-tab>
            <app-tab id="in_progress">
              <ng-template appTabTitle>
                <app-icon name="flag-fill" class="flag-icon flag-progress"></app-icon>Order In Progress <span
                  class="ms-3">{{orderSummary?.in_progress}}</span>
              </ng-template>
              <ng-template appTabContent>
                <app-order-list status="in_progress"></app-order-list>
              </ng-template>
            </app-tab>
            <app-tab id="completed">
              <ng-template appTabTitle>
                <app-icon name="flag-fill" class="flag-icon flag-completed"></app-icon>Order Completed <span
                  class="ms-3">{{orderSummary?.completed}}</span>
              </ng-template>
              <ng-template appTabContent>
                <app-order-list status="completed"></app-order-list>
              </ng-template>
            </app-tab>
          </app-tabset>
        </div>
      </div>
    </div>
  </div>
</section>