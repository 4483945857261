
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LayoutService } from '../../services/layout.service';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnDestroy {
  unsubscriber = new Subject<void>();
  breadcrumbs: Array<Object>;
  dynamicBreadCrumb: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private layoutService: LayoutService
  ) {
    this.layoutService.getBreadcrumb$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((breadcrumb: any) => {
        if (breadcrumb) {
          this.dynamicBreadCrumb = breadcrumb;
        }
      });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(event => {  // note, we don't use event
        this.buildBreadcrumb();
      });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  buildBreadcrumb() {
    this.breadcrumbs = [];
    let currentRoute = this.route.root,
      url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      childrenRoutes.forEach(routes => {
        if (routes.outlet === 'primary') {
          const routeSnapshot = routes.snapshot;
          url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
          if (routes.snapshot.data.breadcrumb !== undefined && routes.snapshot.data.breadcrumb !== '') {
            let status = true;
            if (routes.snapshot.data.status !== undefined) {
              status = routes.snapshot.data.status;
            }
            const label = routes.snapshot.data.breadcrumb === 'Details' && this.dynamicBreadCrumb ? this.dynamicBreadCrumb : routes.snapshot.data.breadcrumb;
            this.breadcrumbs.push({
              label: label,
              status: status,
              url: url
            });

          }
          currentRoute = routes;
        }
      });
    } while (currentRoute);
  }
}
