import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  constructor(
    public updates: SwUpdate,
  ) {
    // If updates are enabled
    if (updates.isEnabled) {
      // poll the service worker to check for updates
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate());
    }
  }

  public checkForUpdates() {
    if (this.updates.isEnabled) {
      // Manually check for updates
      this.updates.checkForUpdate().then((updateFound) => {
        if (updateFound) {
          // If an update is available, activate it and reload the page
          this.updates.activateUpdate().then(() => {
            document.location.reload();
          });
        }
      }).catch((err) => {
        console.error('Error while checking for updates:', err);
      });
    }
  }

}
