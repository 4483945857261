import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { SalesOneProCommonModule } from '@shared/common.module';
import { OrderListComponent } from './components/order-list/order-list.component';
import { IsamplePreviewComponent } from './components/isample-preview/isample-preview.component';
import { AddressDetailsComponent } from './components/address-details/address-details.component';
@NgModule({
  declarations: [
    OrderListComponent,
    IsamplePreviewComponent,
    AddressDetailsComponent
  ],
  imports: [
    SalesOneProCommonModule,
    CommonModule,
    NgSelectModule
  ],
  exports: [
    OrderListComponent,
    IsamplePreviewComponent,
    AddressDetailsComponent,
    NgSelectModule
  ]
})
export class SharedModule { }
