import {
  timer as observableTimer,
  interval as observableInterval,
  of as observableOf,
  Observable,
  BehaviorSubject,
  ReplaySubject
} from 'rxjs';

import {distinctUntilChanged, map, mergeMap, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { User } from '../models';
import { ToasterService } from './toaster.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  public isCaptchaRequiredSubject = new BehaviorSubject<boolean>(false);
  public isCaptchaRequired = this.isCaptchaRequiredSubject.asObservable();

  public navigationSubject = new BehaviorSubject<any>([]);
  public navigation = this.navigationSubject.asObservable();

  public leftNavigationSubject = new BehaviorSubject<any>([]);
  public leftNavigation = this.leftNavigationSubject.asObservable();

  public isCatchaRequiredSubject = new BehaviorSubject<boolean>(false);
  public isCatchaRequired = this.isCatchaRequiredSubject.asObservable();

  public refreshSubscription: any;
  redirectUrl: string;
  cachedRequests: Array<HttpRequest<any>> = [];

  constructor (
    private apiService: ApiService,
    private router: Router,
    private storage: StorageService,
    private toastr: ToasterService,
  ) {}

  getCaptchaToken(): String {
    return this.storage.retrieve('captchaToken');
  }

  getToken(): String {
    return this.storage.retrieve('authToken');
  }

  saveToken(token: String) {
    this.storage.store('authToken', token);
  }

  getBuildKey(): String {
    return this.storage.retrieve('buildKey');
  }

  saveBuildKey(buildKey: String) {
    this.storage.store('buildKey', buildKey);
  }

  isTokenExpired(): boolean {
    const expiresAt = parseInt(this.storage.retrieve('exp_date'), 10);
    return Date.now() > expiresAt;
  }

  collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.getToken()) {
      const token = this.getToken();
      const tokenExpired = this.isTokenExpired();
      if (tokenExpired) {
        // Remove any potential remnants of previous auth states
        this.purgeAuth();
      } else {
        const temp = {'access_token': this.getToken()};
        this.setAuth(temp);
        this.startupTokenRefresh();
      }
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(data: any) {
    // Save JWT sent from server in localstorage
    this.saveToken(data.access_token);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);

    this.getUserInfo();
    this.getCountryStateList();
    this.getOrderOptions();
  }

  getUserInfo() {
    if (!this.storage.retrieve('user')) {
      this.apiService.get('/auth-user')
      .subscribe(response => {
        if (!response['settings']) {
          response['settings'] = {};
        }
        this.storage.store('user', response);
        // Set current user data into observable
        this.currentUserSubject.next(response);
      });
    } else {
      const user = this.storage.retrieve('user');
      this.currentUserSubject.next(user);
    }
  }

  updateUserInfo() {
    this.apiService.get('/auth-user')
      .subscribe(response => {
        if (!response['settings']) {
          response['settings'] = {};
        }
        this.storage.store('user', response);
        this.currentUserSubject.next(response);
      });
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.revokeTokenNoResponse();
    const captchatoken = this.storage.retrieve('captchaToken');
    this.storage.clearAll();
    this.storage.store('captchaToken', captchatoken);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Unschedule the token refresh
    this.unscheduleRefresh();
  }

  attemptAuth(type, credentials): Observable<any> {
    const creds = 'grant_type=password&password='
     + encodeURIComponent(credentials['password']) + '&username=' + encodeURIComponent(credentials['username']);
    return this.apiService.authPost('/token/', creds).pipe(
    map(
      data => {
        this.setAuth(data);
        const expiresAt: any = data.expires_in * 1000 + new Date().getTime();

        this.storage.store('exp_delay', data.expires_in.toString());
        this.storage.store('exp_date', expiresAt);
        this.storage.store('refresh_token', data.refresh_token);

        this.scheduleRefresh();
        return data;
      }
    ));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService
    .put('/user', { user }).pipe(
    map(data => {
      // Update the currentUser observable
      this.currentUserSubject.next(data.user);
      return data.user;
    }));
  }

  scheduleRefresh() {
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    const idToken = this.getToken();
    const source = observableOf(idToken).pipe(mergeMap(
      token => {
        // tslint:disable-next-line:radix
        let delay = parseInt(this.storage.retrieve('exp_delay'));
        const refreshTokenThreshold = 10;
        delay = (delay - refreshTokenThreshold) * 1000;
        // tslint:disable-next-line:radix
        return observableInterval(delay);
      }));

    this.refreshSubscription = source.subscribe(() => {
      this.getNewJwt().subscribe(
        (res) =>  {},
        (error) => {
          this.purgeAuth();
          console.log('-> Refresh error:' + JSON.stringify(error));
          this.router.navigateByUrl('/login');
        }
      );
    });
  }

  startupTokenRefresh() {
    console.log('startupTokenRefresh');
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    const idToken = this.getToken();
    const tokenExpired = idToken ? this.isTokenExpired() : true;
    if (!tokenExpired) {
      const source = observableOf(idToken).pipe(mergeMap(
        token => {
        // Get the expiry time to generate

        const now: any = new Date().valueOf();

        // tslint:disable-next-line:radix
        const date: any =  this.storage.retrieve('exp_date') ? parseInt(this.storage.retrieve('exp_date')) : 0;
        const temp = new Date(date).getTime();
        const refreshTokenThreshold = 10; // seconds

        // a delay in milliseconds
        let delay: any = temp - now;
        (delay < refreshTokenThreshold ) ? delay = 1 : delay = delay - refreshTokenThreshold;

        // Use the delay in a timer to
        // run the refresh at the proper time
        return observableTimer(delay);
      }));

       // Once the delay time from above is
       // reached, get a new JWT and schedule
       // additional refreshes
       source.subscribe(() => {
        this.getNewJwt().subscribe(
          (res) => {
          this.scheduleRefresh();
          },
          (error) => {
            this.purgeAuth();
            console.log('-> Refresh error:' + JSON.stringify(error));
            this.router.navigateByUrl('/login');
          }
        );
       });
    }
  }

  unscheduleRefresh() {
    // Unsubscribe from the refresh
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  getNewJwt() {
    const refreshToken = this.storage.retrieve('refresh_token');
    const creds = 'grant_type=refresh_token&refresh_token=' + encodeURIComponent(refreshToken);

    return this.apiService.authPost('/token/', creds).pipe(
      map(
        data => {
          this.setAuth(data);
          this.storage.store('exp_delay', data.expires_in.toString());
          const expiresAt = (data.expires_in * 1000) + new Date().getTime();
          this.storage.store('exp_date', expiresAt);
          this.storage.store('refresh_token', data.refresh_token);
        }
      ));
  }

  getCountryStateList() {
    if (!this.storage.retrieve('country-state-list')) {
      this.apiService.get('/country_state_list')
      .subscribe(response => {
        this.storage.store('country-state-list', response);
      });
    }
  }

  getOrderOptions() {
    if (!this.storage.retrieve('orderOptions')) {
      this.apiService.get('/order_option_list')
      .subscribe(response => {
        this.storage.store('orderOptions', response);
      });
    }
  }

  getAuthUserDetails() {
    if (this.storage.retrieve('user')) {
      return this.storage.retrieve('user');
    }
    return false;
  }


  revokeTokenNoResponse() {
    if (this.storage.retrieve('authtoken')) {
      const token = this.storage.retrieve('authtoken');
      const creds = 'token=' + encodeURIComponent(token);
      this.apiService.authPost('/revoke_token/', creds)
      .subscribe(response => {});
    }
  }

  revokeToken() {
    const token = this.storage.retrieve('authtoken');
    const creds = 'token=' + encodeURIComponent(token);

    return this.apiService.authPost('/revoke_token/', creds)
    .pipe(
      map(response => {
        return response;
      }));
  }

  validateCaptcha(data) {
    const url = '/validate_recaptcha';
    return this.apiService
    .post(url, data).pipe(
      map(response => {
        if (response.recaptcha_token) {
          this.storage.store('captchaToken', response.recaptcha_token);
          this.isCaptchaRequiredSubject.next(false);
        }
        return response;
      })
    );
  }

  resetPassword(data) {
    return this.apiService
      .post('/users/setpassword', data).pipe(
      map(response => {
        return response;
      }));
  }

  forgotPassword(data) {
    return this.apiService
      .post('/users/forgot_password', data).pipe(
        tap(
          _ => {
            this.toastr.success(
              ' Password reset link has been sent to your email '
            );
          },
          _ =>
            this.toastr.success(
              'Your Email is not registered with us'
            )
        )
      );
  }
}
