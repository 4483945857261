import { Component, ElementRef, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@shared/index';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  errors: any;
  invalidFormData: boolean;
  formSubmitted: boolean;
  authForm: FormGroup;
  formattedErrors: Array<string> = [];
  formInvalid: boolean;

  fwdPasswordApiErrors: any;
  forgotPasswordForm: FormGroup;
  invalidForgotPwdFormData: boolean;
  forgotPwdFormSubmitted: boolean;
  showFgtPwdForm: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    private el: ElementRef,
    public vcr: ViewContainerRef
  ) {
    this.authForm = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'grant_type': 'password',
    });

    this.forgotPasswordForm = this.fb.group({
      'email': ['', Validators.required]
    });
  }

  ngOnInit() {
    const target = this.el.nativeElement.querySelector('.form-control');
    if (target) {
      target.focus();
    }
  }

  submitForm() {
    this.invalidFormData = true;
    if (this.authForm.valid) {
      this.formInvalid = false;
      this.invalidFormData = false;
      const credentials = this.authForm.value;
      this.formSubmitted = true;
      this.authService
        .attemptAuth('', credentials)
        .subscribe(
          data => this.router.navigateByUrl('/'),
          err => {
            this.formInvalid = true;
            this.errors = err;
            this.formatError(err);
            this.formSubmitted = false;
          }
        );
    }
  }

  formatError(errorList) {
    this.formattedErrors = [];
    const parent = this;
    if (typeof errorList === 'object') {
      Object.keys(errorList).map(function (objectKey, index) {
        const value = errorList[objectKey];
        if (typeof errorList[objectKey] === 'string') {
          parent.formattedErrors.push(value);
        } else {
          if (value.length > 0) {
            parent.formattedErrors.push(value[0]);
          }
        }
      });
    } else if (errorList) {
      this.formattedErrors.push(errorList);
    }
  }

  submitForgotPwdForm() {
    this.invalidForgotPwdFormData = true;
    if (this.forgotPasswordForm.valid) {
      this.formInvalid = false;
      this.invalidForgotPwdFormData = false;
      this.forgotPwdFormSubmitted = true;
      this.authService
        .forgotPassword(this.forgotPasswordForm.value)
        .subscribe(
          data => {
            this.router.navigateByUrl('/'),
              this.forgotPwdFormSubmitted = false;
          },
          err => {
            this.fwdPasswordApiErrors = err;
            this.forgotPwdFormSubmitted = false;
          }
        );
    }
  }
  toggleBack() {
    this.showFgtPwdForm = !this.showFgtPwdForm;
    this.forgotPasswordForm.reset();
    this.invalidForgotPwdFormData = false;
    this.invalidFormData = false;
  }
}