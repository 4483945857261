import { Component, OnInit } from '@angular/core';

// services
import { OrderService } from '@core/orders/services/order.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  loadingOrderSummary: boolean;
  orderSummary: any;

  constructor(
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.getOrderSummary();
  }

  getOrderSummary() {
    this.loadingOrderSummary = true;
    this.orderService.getOrderSummary().subscribe({
      next: (response: any) => {
        this.loadingOrderSummary = false;
        this.orderSummary = response;
      },
      error: (err: any) => {
        this.loadingOrderSummary = false;
      }
    });
  }
}
