<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
        <h1 class="float-left display-3 me-4">404</h1>
        <h4 class="pt-3">Oops! You're lost.</h4>
        <p class="text-muted">The page you are looking for was not found.</p>
        </div>
        <div class="input-group mb-2">
          <input type="text" placeholder="search ..." class="form-control">
          <span class="input-group-btn">
            <button class="btn btn-default" type="button"><i class="fa fa-search"></i></button>
          </span>
        </div>
        <div class="row">
          <div class="col-lg-12"> 
            <div class="btn-group btn-group-justified">
              <a [routerLink]="['/dashboard']" class="btn btn-info">Return Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>