<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item nav-dropdown">
        <a class="nav-link" [routerLink]="['/dashboard']" routerLinkActive="active">
          <app-icon name="speedometer2"></app-icon>Dashboard
        </a>
      </li>
      <li class="nav-item nav-dropdown">
        <a class="nav-link nav-dropdown-toggle" href="#submenu" data-bs-toggle="collapse"  role="button"
          aria-expanded="false" aria-controls="submenu">
          <app-icon name="cart-fill"></app-icon>
          Orders
        </a>
        <ul class="nav-dropdown-items collapse show" id="submenu">
          <li class="nav-item">
            <a routerLinkActive="active" class="nav-link" [routerLink]="['/orders/all-orders']">
              <app-icon name="icon-puzzle"></app-icon>
              All Orders
            </a>
          </li>
          <li class="nav-item">
            <a routerLinkActive="active" class="nav-link" [routerLink]="['/orders/pending-approval']">
              <app-icon name="icon-puzzle"></app-icon>
              Orders Pending Approval <span class="badge d-none">(1)</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLinkActive="active" class="nav-link" [routerLink]="['/orders/in-progress']">
              <app-icon name="icon-puzzle"></app-icon>
              Orders In Progress <span class="badge d-none">(0)</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLinkActive="active" class="nav-link" [routerLink]="['/orders/completed']">
              <app-icon name="icon-puzzle"></app-icon>
              Orders Completed <span class="badge d-none">(12)</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-dropdown">
        <a class="nav-link" [routerLink]="['/account-details']" routerLinkActive="active">
          <app-icon name="person-circle"></app-icon>Account Details
        </a>
      </li>
    </ul>
  </nav>
</div>