import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-isample-preview',
  templateUrl: './isample-preview.component.html',
  styleUrls: ['./isample-preview.component.scss']
})
export class IsamplePreviewComponent {
  @Input() image: string;
  loaded: boolean;

  constructor() { }

  /**
   * Triggered when original image fails to load
   */
  imageLoadFail(event) {
    event.target.className = 'w-100 img-fluid';
    event.target.src = 'assets/img/no-preview.png';
  }
}
